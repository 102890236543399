@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --black-family: 'Black Sans';
  --white: #ffffff;
  --text-color: #545454;
  --text-light: #878787;
  --bizedge-blue: #5b7ed7;
  --button-primary-color: #2898a4;
  --bizedge-yellow: #f8b636;
  --bizedge-light-yellow: #fef6e6;
  --bizedge-dark-grey: #a8a8a8;
  --input-field-error: #f44336;
  --bizedge-field-error: #ff7372;

  /* --button-contained-primary-text-color: #ffffff; */
  /* --button-outlined-primary-text-color: #545454; */
  /* --button-upload-text-color: #878787; */
}
.u-black-sans {
  font-family: var(--black-family);
}
.u-text-light {
  color: var(--text-light);
}
@font-face {
  font-family: 'Black Sans';
  font-styled: 'black';
  font-weight: 900;
  src: url('./common/fonts/black-sans/blacksansblackwebfont.otf');
}

@font-face {
  font-family: 'Black Sans';
  font-styled: 'bold';
  font-weight: 700;
  src: url('./common/fonts/black-sans/blacksansboldwebfont949ef363c646d9c2456af9d67a94778f.otf');
}

@font-face {
  font-family: 'Black Sans';
  font-styled: 'semibold';
  font-weight: 600;
  src: url('./common/fonts/black-sans/blacksanssemiboldwebfont647663a8d54cb4e2551213de87a56073.otf');
}

@font-face {
  font-family: 'Black Sans';
  font-styled: 'medium';
  font-weight: 500;
  src: url('./common/fonts/black-sans/blacksanscondensedmediumwebfont.otf');
}

@font-face {
  font-family: 'Black Sans';
  font-styled: 'regular';
  font-weight: 400;
  src: url('./common/fonts/black-sans/blacksansregularwebfontd6761cbf250866e8870d97f03fa629a8.otf');
}

@font-face {
  font-family: 'Black Sans';
  font-styled: 'light';
  font-weight: 300;
  src: url('./common/fonts/black-sans/blacksanslightwebfont.otf');
}

* {
  box-sizing: border-box;
  font-family: 'Black Sans' !important;
}

body {
  margin: 0 !important;
  padding: 0 !important;
  overflow-x: hidden !important;
  background-color: white !important;
}

.pac-container {
  z-index: 9050 !important;
}

body .MuiAutocomplete-listbox li {
  padding: 1rem;
  border-bottom: 1px solid #f2f2f2;
  cursor: pointer;
}

body .MuiAutocomplete-listbox li:hover {
  color: #2898a4;
  background: none;
}

body .MuiAutocomplete-listbox > :last-child {
  border-bottom: none;
}

.side-drawer .MuiDrawer-paper {
  width: 45% !important;
}

.widget-drawer .MuiDrawer-paper {
  width: 30% !important;
}

.side-drawer2 .MuiDrawer-paper {
  width: 35.1% !important;
  @media (max-width: 640px) {
    width: 90% !important;
  }
}

.side-drawer3 .MuiDrawer-paper {
  width: 448px !important;
  @media (max-width: 640px) {
    width: 90% !important;
  }
}

.MuiDrawer-paperAnchorLeft {
  width: 338px !important;
}

button {
  text-transform: capitalize !important;
}

/* @media only screen and (max-width: 1280px) {
  .MuiTextField-root input::placeholder {
    font-size: 12px;
  }
  input {
    font-size: 12px !important;
  }
  .MuiInputLabel-root {
    font-size: 12px !important;
  }
} */

.d-test_border {
  border: 1px solid red;
}
.react-joyride__spotlight {
  display: none;
}

.__floater__body .react-joyride__tooltip {
  border-radius: 15px !important;
  padding: 25px 21px 24px !important;
}

.makeStyles-dropzone-141 {
  background: #f2f2f2 !important;
}

.MuiTextField-root {
  /* display: block; */
  margin: 1.7% 0 !important;
  /* margin-bottom: 21px !important; */
}

.MuiCircularProgress-root {
  display: block !important;
  margin: auto !important;
}

.MuiSelect-outlined.MuiSelect-outlined:focus {
  background: transparent;
}

.rdrDefinedRangesWrapper {
  display: none;
}

.swiper-button-next {
  background-image: url('./common/images/Group 1548.svg');
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  top: 15% !important;
  right: 25px !important;
}

.swiper-button-prev {
  background-image: url('./common/images/Group\ 1549.svg');
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  top: 15% !important;
  left: 92% !important;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  display: none;
}

.custom-label-onboarding::before {
  background-image: url('./common/images/checked.svg');
}

.debounce_input {
  width: 250px;
  height: 41px;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  padding: 0 1rem;
  font-size: 15px;
}

/* .debounce_input[type=text]:focus {
  border: 2px solid #2898A4;
} */

.rdebounce_input:focus {
  border: 2px solid #2898a4;
}

/* Invoice Style */

.company-invoice-logo {
  width: 18mm;
  height: 18mm;
  border: 1px solid #e1e1e1;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
}

.header {
  font-size: 5mm;
  font-weight: 700;
  margin: 0;
}

.header_section_1 {
  padding: 1em 2em;
  border-top-left-radius: 10px;
  border-top: 1px solid #e1e1e1;
  border-left: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  margin-top: 21px;
}

.header_section_2 {
  padding: 1em 2em;
  border-top-right-radius: 10px;
  border-top: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  margin-top: 21px;
}

.header_section {
  padding: 1em 2em;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  margin-top: 21px;
}

.table_header {
  background: #f2f2f2 !important;
  color: #545454;
  font-weight: 600;
  font-size: 14.22px;
  width: 100%;
  padding: 2mm;
}

.td {
  padding: 1em 2em;
  border-bottom: 1px solid #e1e1e1;
}

.td_h {
  padding: 1em 2em;
  border-bottom: 1px solid #e1e1e1;
  background: #f2f2f2 !important;
  color: #545454;
  font-weight: 600;
  font-size: 14.22px;
  width: 100%;
}

.td_1 {
  padding: 1em 2em;
  border-left: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
}

.td_body {
  width: 100%;
  padding: 2mm;
  font-size: 14.22px;
}

.td_2 {
  padding: 1em 2em;
  border-right: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
}

.sub-header {
  font-size: 4mm;
  font-weight: 700;
}

.mt-3 {
  margin-top: 3mm;
}

.mt-4 {
  margin-top: 4mm;
}

.mb-3 {
  margin-bottom: 3mm;
}

.me-4 {
  margin-right: 3mm;
}

.me-2 {
  margin-right: 2mm;
}

.bg-dark {
  background: #000;
  color: #ffffff;
  width: 100%;
  padding: 2mm;
}

.mt-1 {
  margin-top: 1mm;
}

.text-right {
  text-align: right !important;
}

.border-bottom {
  border-bottom: 1px #e8e8e8 solid;
  padding-bottom: 4mm;
}

.description-width {
  width: 50mm !important;
}

.page {
  width: 145mm;
  /* min-height: 29.7cm; */
  padding: 2mm;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border: 5px #fff solid;
}

.page-2 {
  width: 100%;
  /* min-height: 29.7cm; */
  padding: 2mm;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border: 5px #fff solid;
}

@page {
  size: A4;
  margin: 0;
}

@media print {
  .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }
}

.a4 {
  width: 210mm;
}

.d-flex {
  display: flex;
}

.d-flex_1 {
  flex: 1;
}
.flex__center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex__end {
  display: flex;
  justify-content: flex-end;
}
.d-flex__end {
  display: flex;
  justify-content: flex-end;
}

.d-flex-col {
  display: flex;
  flex-direction: column;
}

.d-h-sm {
  height: 100px;
}
.d-h-md {
  height: 200px;
}

.d-h-full {
  height: 100%;
}

.d-w-full {
  width: 100%;
}

.align-items-center {
  align-items: center;
}
.align-items-end {
  align-items: end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-end {
  justify-content: end;
}

#pointer {
  cursor: pointer;
}

/* Hide Scrollbar  */
/* -webkit- (Chrome, Safari, newer versions of Opera) */
.newProductModal::-webkit-scrollbar,
.content::-webkit-scrollbar,
.job_detail_section::-webkit-scrollbar,
.detail_content::-webkit-scrollbar {
  width: 0 !important;
  display: none !important;
  overflow-x: hidden !important;
  scroll-behavior: smooth;
}

/* -moz- (Firefox): */
.newProductModal {
  overflow: -moz-scrollbars-none;
  scroll-behavior: smooth;
}

/* -ms- (Internet Explorer +10): */
.newProductModal {
  -ms-overflow-style: none;
}

* {
  box-sizing: border-box;
  font-family: 'Black Sans';
}

body {
  margin: 0 !important;
  padding: 0 !important;
  overflow-x: hidden !important;
  background-color: white !important;
}

.pac-container {
  z-index: 9050 !important;
}

body .MuiAutocomplete-listbox li {
  padding: 1rem;
  border-bottom: 1px solid #f2f2f2;
  cursor: pointer;
}

body .MuiAutocomplete-listbox li:hover {
  color: #2898a4;
  background: none;
}

body .MuiAutocomplete-listbox > :last-child {
  border-bottom: none;
}

.side-drawer .MuiDrawer-paper {
  width: 45% !important;
}

.side-drawer2 .MuiDrawer-paper {
  width: 35.1% !important;
  @media (max-width: 640px) {
    width: 90% !important;
  }
}
.mobile-profile {
  @media (max-width: 640px) {
    display: block;
    position: absolute;
    right: 15px;
    top: 10px;
  }
}

button {
  text-transform: capitalize !important;
}

/* @media only screen and (max-width: 1280px) {
  .MuiTextField-root input::placeholder {
    font-size: 12px;
  }
  input {
    font-size: 12px !important;
  }
  .MuiInputLabel-root {
    font-size: 12px !important;
  }
} */

.MuiTextField-root {
  /* display: block; */
  margin: 1.7% 0 !important;
  /* margin-bottom: 21px !important; */
}

.MuiCircularProgress-root {
  display: block !important;
  margin: auto !important;
}

.MuiSelect-outlined.MuiSelect-outlined:focus {
  background: transparent;
}

/* Invoice Style */

.company-invoice-logo {
  width: 18mm;
  height: 18mm;
  border: 1px solid #e1e1e1;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
}

.header {
  font-size: 5mm;
  font-weight: 700;
  margin: 0;
}

.header_section_1 {
  padding: 1em 2em;
  border-top-left-radius: 10px;
  border-top: 1px solid #e1e1e1;
  border-left: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  margin-top: 21px;
}

.header_section_2 {
  padding: 1em 2em;
  border-top-right-radius: 10px;
  border-top: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  margin-top: 21px;
}

.header_section {
  padding: 1em 2em;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  margin-top: 21px;
}

.table_header {
  background: #f2f2f2 !important;
  color: #545454;
  font-weight: 600;
  font-size: 14.22px;
  width: 100%;
  padding: 2mm;
}

.td {
  padding: 1em 2em;
  border-bottom: 1px solid #e1e1e1;
}

.td_h {
  padding: 1em 2em;
  border-bottom: 1px solid #e1e1e1;
  background: #f2f2f2 !important;
  color: #545454;
  font-weight: 600;
  font-size: 14.22px;
  width: 100%;
}

.td_1 {
  padding: 1em 2em;
  border-left: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
}

.td_body {
  width: 100%;
  padding: 2mm;
  font-size: 14.22px;
}

.td_2 {
  padding: 1em 2em;
  border-right: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
}

.sub-header {
  font-size: 4mm;
  font-weight: 700;
}

.mt-3 {
  margin-top: 3mm;
}

.mt-4 {
  margin-top: 4mm;
}

.mb-3 {
  margin-bottom: 3mm;
}
.mb-4 {
  margin-bottom: 4mm;
}

.me-4 {
  margin-right: 3mm;
}

.me-2 {
  margin-right: 2mm;
}

.bg-lightgrey {
  background-color: #f9f9f9;
  width: 100%;
}

.bg-dark {
  background: #000;
  color: #ffffff;
  width: 100%;
  padding: 2mm;
}

.mt-1 {
  margin-top: 1mm;
}

.text-right {
  text-align: right !important;
}

.border-bottom {
  border-bottom: 1px #e8e8e8 solid;
  padding-bottom: 4mm;
}

.description-width {
  width: 50mm !important;
}

.page {
  width: 145mm;
  padding: 2mm;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border: 5px #fff solid;
}

.page-2 {
  width: 100%;
  padding: 2mm;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border: 5px #fff solid;
}

@page {
  size: A4;
  margin: 0;
}

@media print {
  .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }
}

.a4 {
  width: 210mm;
}

.d-flex {
  display: flex;
}
.d-flex-col {
  display: flex;
  flex-direction: column;
}

.align-items-center {
  align-items: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-end {
  justify-content: end;
}

.d-gap-xxs {
  gap: 0.25rem;
}
.d-gap-xs {
  gap: 0.5rem;
}
.d-gap-sm {
  gap: 1rem;
}
.d-gap {
  gap: 1.3rem;
}
.d-gap-lg {
  gap: 1.7rem;
}
.d-gap-xl {
  gap: 2rem;
}
.d-gap-xxl {
  gap: 3rem;
}
#pointer {
  cursor: pointer;
}

iframe {
  display: none;
}

#fc_frame #fc_widget {
  display: block;
}

[name^='paystack-checkout-background'] {
  display: block;
}

[name^='paystack-checkout'] {
  display: block;
}

.react-calendar {
  box-shadow: 1px 5px 22px 4px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  border: 1px solid #e1e1e1 !important;
}

.react-calendar__tile--active {
  background: #2898a4 !important;
}

.react-calendar__tile--now {
  background: #2898a4 !important;
  color: white;
}

.react-calendar__month-view__days__day--weekend {
  color: #2898a4 !important;
}

.react-calendar__tile--active {
  color: white !important;
}

.ant-picker {
  background: #d6f2f5;
  border-radius: 24px;
}

.MuiList-root {
  font-size: 200rem;
}

.MuiLinearProgress-colorPrimary {
  background: white !important;
}

.otp_style {
  height: 50px;
  display: flex;
  margin: 0 0.5rem;
  font-size: 2.3rem;
  border-radius: 4px;
  background: #ffffff;
  width: 100% !important;
  box-sizing: border-box;
  border: 1px solid #cdcdcd;
}

.ant-radio-inner:after {
  background: #ffffff !important;
}

.ant-radio-checked .ant-radio-inner,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus .ant-radio-inner {
  border-color: #2898a4 !important;
  background: #2898a4 !important;
}
.u-field_flex {
  display: flex;
  gap: 1.5rem;
}

.mui_btn_primary_contained {
  background: hsl(186, 61%, 40%);
}
.mui_btn_primary_outlined {
  border-color: #2898a4;
  color: #2898a4;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #a8a8a8;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a8a8a8;
  border-radius: 10px;
}
.swiper {
  padding-bottom: 20px;
}
.swiper-pagination {
  bottom: -5px !important;
  z-index: 99 !important;
}
.swiper-pagination-bullet-active {
  width: 20px;
  border-radius: 20px;
  height: 8px;

  background: #a8a8a8;
}

.number_input_rm_icon input::-webkit-outer-spin-button,
.number_input_rm_icon input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* .number_input_rm_icon input[type="number"] {
  -moz-appearance: textfield;
} */

/* CKEditor Style */

.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  padding-left: 20px !important;
}

.ck.ck-editor__main > .ck-editor__editable {
  padding-left: 20px !important;
}

/* this is wrong , it has over ridden every list style please do something about it. @Midecodes*/
li {
  list-style: disc !important;
  padding: 5px 0;
}

.attendance_height {
  height: calc(100vh + 30px);
  background: #fcdc9c;
  width: 100%;
}

.payroll_height {
  width: 100%;
  height: 100vh;
  background: #ff7272;
}

.shift_height {
  width: 100%;
  height: 100vh;
  background: #883c82;
}

.attendance_onboarding_content {
  height: calc(100vh + 30px);
  overflow-y: scroll;
}

.payroll_onboarding_content {
  height: 100vh;
  overflow-y: scroll;
}

.hide_scrollbar {
  overflow-y: scroll !important;
}

.hide_scrollbar::-webkit-scrollbar {
  display: none !important;
}

/* margin utils */

.d-mt-xs {
  margin-top: 0.5rem;
}

.d-mt-sm {
  margin-top: 0.8rem;
}

.d-mt {
  margin-top: 1rem;
}
.d-mt-md {
  margin-top: 1.5rem;
}
.d-mt-lg {
  margin-top: 2rem;
}
.d-mt-xl {
  margin-top: 3rem;
}

tbody > tr:hover > td {
  background: #eaf8fa !important;
}
.taction {
  display: none;
}
tbody > tr:hover > td > div > .taction {
  display: flex;
}

.attendance_onboarding_height {
  height: calc(100vh - 430px) !important;
}

/* 
.table_scroll_width::-webkit-scrollbar{
  display: none !important;
} */
@media only screen and (max-width: 576px) {
  .ant-pagination .ant-pagination-options {
    padding-top: 10px;
    display: block;
  }
}
css-1aquho2-MuiTabs-indicator {
  background-color: #2898a4 !important;
}

.shift-time {
  background: transparent;
}

.shift-time:hover {
  background: #d6f2f5;
}

/* Add this to your global CSS file */
.scrollbar-hide {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.scrollbar-hide::-webkit-scrollbar {
  display: none; /* WebKit (Chrome, Safari, Edge) */
}

.react-datepicker {
  border: none !important;
  width: 317px;
  font-size: 14px;
  border-radius: 20px !important;
  padding: 10px;
  background: #ffffff;
  box-shadow: 1px 5px 22px 4px rgba(0, 0, 0, 0.15);
}

.react-datepicker .react-datepicker__month-container {
  width: 100%;
}

.react-datepicker__header {
  border-bottom: none !important;
  font-weight: 600;
  color: #545454;
}

/* Scoped Custom Day Size */
.react-datepicker .react-datepicker__day {
  font-size: 12px;
  font-weight: 600;
  color: #545454;
}
.react-datepicker .react-datepicker__day-names {
  display: flex;
  justify-content: space-around;
  margin: 10px 0px;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--in-range,
.react-datepicker__day--range-start,
.react-datepicker__day--range-end {
  font-weight: bold;
  background-color: #eaf8fa !important;
}

.react-datepicker .react-datepicker__header {
  background: transparent;
}

.react-datepicker .custom-datepicker-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
}
/* Scoped Custom Weekend Styles */
.react-datepicker .react-datepicker__week {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin: 10px 0px;
}
.react-datepicker__day--outside-month {
  color: #b0b0b0 !important; /* Light gray */
  opacity: 0.6; /* Slight transparency */
}
